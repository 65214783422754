.add-poi-tooltip-box {
  display: flex;
  flex-direction: row;
}

.left-align .tooltip-inner {
  text-align: left;
}

.search-input {
  margin-bottom: 30px;
  border: 1px solid #7e7e7e;
  color: black;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 40px;
  border-radius: 4px;
}

.editor-panel {
  flex: 1;
  width: inherit;
  background-color: #dedede;
  z-index: 899;
  height: 100%;
  .add-poi-icon {
    margin-right: 20px;
    padding-bottom: 20px;
  }

  .add-poi-text {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.14;
    letter-spacing: normal;
  }

  .editor-panel-content {
    flex: 1;
    padding: 21px;

    p {
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #3d3d3d;
    }

    .inline-label {
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.29px;
      margin-bottom: 19px;

      span {
        font-weight: normal;
      }
    }

    // Found in two spots, css is competing with itself. Label.scss
    .label-text {
      color: #000;
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: 0.29px;
      margin-bottom: 10px;

      + p.label-text {
        font-size: 16px;
        color: #000;
      }
    }

    .no-bottom-margin {
      margin-bottom: 0px;
    }

    // These styles will only affect the Entity page, should probably re-organize this
    .entity-body {
      hr {
        color: #000;
        border-top: 1px solid #000;
        margin: 20px 0px;
      }

      input {
        height: 40px;
      }

      textarea {
        padding: 16px 20px;
        width: 100%;
        border-radius: 8px;
        height: 96px;
      }

      .entityInputField {
        width: 100%;
        border-radius: 4px;
        border: solid 1px #7e7e7e;
        background-color: #ffffff;
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 24px;
        letter-spacing: normal;
        color: #000;
        margin-bottom: 23px;

        &:focus {
          box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
        }

        &.disabled {
          background-color: #d8d8d8;
        }
      }

      .required {
        color: #c51008;
      }

      .sub-label-italicized {
        color: #5d5d5d;
        font-family: 'Roboto', sans-serif;
        font-size: 10px;
        font-style: italic;
      }

      // 10px is spacing-xs in zeplin, make classes for this?
      .extra-info-header {
        font-size: 14px;
        margin-bottom: 10px;
      }

      // 5px is grouped-elements in zeplin, make class for this?
      .extra-info-sub-header {
        font-size: 10px;
        margin-bottom: 5px;
      }

      button.blue-link {
        display: block;
        font-size: 10px;
        border: none;
        letter-spacing: 0.18px;
        color: #0000ec;
        background-color: inherit;
        text-decoration: underline;
        padding: 0;
        margin-top: 14px;
      }

      button.left {
        margin-right: auto;
      }

      button.right {
        margin-left: auto;
      }

      button.no-margin-top {
        margin-top: 0px;
      }

      button.margin-bottom {
        margin-bottom: 12px;
      }

      p.right {
        text-align: right;
      }

      .underline {
        text-decoration: underline;
      }

      p.snapShotSuccess {
        display: block;
        font-size: 10px;
        border: none;
        letter-spacing: 0.18px;
        color: #5cb85c;
        background-color: inherit;
        padding: 0;
        margin-left: auto;
        margin-top: 14px;
      }

      div.snapShotError {
        color: #910000;
        font-size: 10px;
        text-align: right;
        margin-top: 12px;
        span.bold {
          font-weight: bold;
        }
      }
    }
  }
}
