.studio-dropdown {
  &.show > .btn.btn-primary,
  &.show > .btn-primary.dropdown-toggle,
  .btn-primary:not(:disabled):not(.disabled):active,
  .btn-primary:not(:disabled):not(.disabled).active {
    color: #000;
    background-color: #fff;
  }

  button.btn-primary {
    width: 100%;
    height: 40px;
    padding: 12px 13px 12px 12px;
    border-radius: 4px;
    border: solid 1px #7e7e7e;
    background-color: #fff;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    text-align: left;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
    margin-bottom: 23px;
  }
  ::after {
    position: absolute;
    right: 16px;
    top: 30%;
  }

  .dropdown-menu {
    padding: 0px;
  }

  .dropdown-menu.show {
    width: 100%;
    margin: 2px 1px 17px 0;
    border-radius: 4px;
    box-shadow: 0 0 4px 0 rgba(109, 109, 109, 0.5);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    overflow-y: scroll;
    overflow-x: hidden;
    color: #000000;
  }

  .dropdown-item.active,
  .dropdown-item:active {
    color: #212529;
    text-decoration: none;
    background-color: lightgrey;
  }

  .dropdown-item:hover,
  .dropdown-item:focus {
    background-color: #eee;
  }
}
