@keyframes iconSlideInitial {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

@keyframes iconSlideSecond {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(200%);
  }
}

.login-container {
  font-family: 'Roboto', sans-serif;
  display: flex;
  flex-direction: column;
  width: 100vw;
  min-height: 100vh;
  margin: 0;
  color: #152939;
  margin: 0;
  padding: 0;
  background: linear-gradient(to bottom, #f6c335, #f9d266 67%, #ffde83);

  .login-body {
    width: 100vw;
    margin: 0;
    text-align: center;
  }
  .login-title {
    margin-top: 100px;
    margin-bottom: 64px;
  }

  form {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 100vw;
    .icons-container {
      top: 117px;
      overflow: hidden;
      width: 100%;
      height: 24px;
      z-index: 0;
      position: absolute;
      opacity: 0;
      transition: opacity 0.5s;

      div[class^='icons'] {
        width: 100%;
        position: absolute;
        display: flex;
        justify-content: space-between;
        transform: translateX(0);
      }
      .icons-1 {
        animation: iconSlideInitial 22s linear;
        animation-fill-mode: forwards;
      }

      .icons-2 {
        left: -100%;
        animation: iconSlideSecond 44s linear infinite;
      }

      .icons-3 {
        left: -100%;
        animation: iconSlideSecond 44s linear 22s infinite;
      }
    }

    .form {
      display: inline-block;
      overflow: hidden;
      padding: 32px;
      z-index: 100;
      width: 570px;
      background-color: white;
      border-radius: 10px;
      text-align: left;
      .form-header {
        width: 100%;
        margin-bottom: 20px;
        .header-label {
          margin-bottom: 22px;
          font-family: 'Roboto', sans-serif;
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: 0.44px;
          text-align: center;
          color: #000000;
        }
        .subheader-label {
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.4px;
          text-align: center;
          color: #0e0e0e;
        }
      }
      .back-arrow-button {
        width: 24px;
        height: 24px;
        top: -8px;
        left: -8px;
        cursor: pointer;
      }
      .form-body {
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        .form-formfield {
          flex-direction: column;
          margin-bottom: 22px;
          max-width: 343px;
          width: 100%;
          align-self: center;

          .input-label {
            text-align: left;
            font-family: 'Roboto', sans-serif;
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: 0.29px;
            color: #000000;
            margin-bottom: 8px;
          }
          .error-label {
            align-self: flex-start;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.4px;
            color: #d93732;
          }
          .error-label-box {
            align-self: flex-end;
            flex-direction: row;
            width: 100%;
          }
          .resend-link {
            align-self: center;
            font-family: 'Roboto', sans-serif;
            font-size: 12px;
            font-weight: normal;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.33;
            letter-spacing: 0.4px;
            cursor: pointer;
            &:hover {
              text-decoration: underline;
            }
          }
          .sub-label-box {
            flex-direction: row;
            width: 100%;
          }
          .email-input,
          .password-input {
            display: block;
            width: 100%;
            padding: 16px 20px;
            border-radius: 8px;
            box-sizing: border-box;
            margin-bottom: 10px;
            font-family: 'Roboto', sans-serif;
            font-size: 14px;
            border: solid 1px #979797;
            &:focus {
              border: 1px solid rgb(53, 75, 216);
            }
          }
          .overlay-container {
            position: relative;

            img {
              position: absolute;
              width: 16px;
              height: 13px;
              top: 21px;
              right: 11px;
            }
          }
        }
      }

      .row {
        flex-direction: row;
        justify-content: center;

        .link-button {
          text-transform: uppercase;
          height: 36px;
          display: inline-block;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 1.25px;
          text-align: center;
          color: black;
          background-color: #ffe658;
          border: none;
          border-radius: 4px;
          padding: 10px 13px 10px 13px;
          min-width: 60px;
          white-space: nowrap;
          vertical-align: middle;
          touch-action: manipulation;
          margin-right: 10px;

          &:hover {
            background-color: #f5d000;
          }
        }

        :last-child.button {
          margin-right: 0;
        }
      }

      .sign-in-footer-section {
        flex-direction: column;
        align-items: center;
        .sign-in-button {
          text-transform: uppercase;
          height: 36px;
          display: inline-block;
          font-family: 'Roboto', sans-serif;
          font-size: 14px;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.14;
          letter-spacing: 1.25px;
          text-align: center;
          color: black;
          background-color: #ffe658;
          border: none;
          border-radius: 4px;
          padding: 10px 13px 10px 13px;
          min-width: 60px;
          white-space: nowrap;
          vertical-align: middle;
          touch-action: manipulation;

          &:hover {
            background-color: #f5d000;
          }

          + .sign-in-button {
            margin-top: 10px;
          }
        }

        .reset-link {
          margin-top: 10px;
          font-family: 'Roboto', sans-serif;
          font-size: 12px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.33;
          letter-spacing: 0.4px;
          color: #0937bf;
          cursor: pointer;
          &:hover {
            text-decoration: underline;
          }
        }
      }
    }

    @media only screen and (max-width: 600px) {
      .icons-container {
        width: 200vw;
      }

      .form {
        width: 100%;
        margin: 0 20px;
      }
    }
  }
  .footer-text {
    margin-top: 87px;
    p {
      width: 100vw;
      font-family: 'Roboto', sans-serif;
      font-size: 18px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #414143;
      a {
        color: #414143;
      }
    }
  }
}
