.label-text {
  color: #000;
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.29px;
  margin-bottom: 10px;

  + p {
    font-size: 16px;
    color: #000;
  }
}
