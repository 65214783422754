div#extraInfoURL {
  button.clear {
    font-size: 10px;
    border: none;
    letter-spacing: 0.18px;
    color: #910000;
    background-color: inherit;
    text-decoration: underline;
    float: right;
    padding: 0;
  }

  .form-group {
    // display: flex;
    margin-bottom: 5px;

    .entityInputField {
      // Have to do this to override the margin-bottom set in EditorPage.scss
      margin-bottom: 0 !important;
    }

    + .form-group {
      margin-top: 20px;
    }
  }

  button.add-more-info {
    display: block;
    font-size: 10px;
    border: none;
    letter-spacing: 0.18px;
    color: #0000ec;
    background-color: inherit;
    text-decoration: underline;
    padding: 0;
    margin-left: auto;
    margin-top: 14px;
  }
}
