.landing-page-container {
  background: #d0d3d5;
  height: 100vh;
  overflow: auto;
  h1 {
    font-weight: 400;
    font-size: 24px;
  }
  .landing-page-background {
    background-image: url('../../assets/landing-bg.png');
    opacity: 0.5;
  }
  .landing-page-list {
    padding-top: 180px;
    padding-bottom: 100px;
    height: 100%;
  }
  .building-list {
    width: 406px;
    overflow-y: scroll;
    max-height: 700px;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
  }
  .editor-root {
    .icon {
      display: none;
    }
    .header {
      margin-left: 21px;
      .title {
        margin-top: 64px;
      }
    }
  }
}
