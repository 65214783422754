.nav-buttons-box {
  position: absolute;
  // left: 16px;
  width: 384px;
  display: flex;
  flex-direction: row;
  z-index: 1001;
}
.back-button {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10;
  margin: 10px 10px 0 22px;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #ffffff;
  &:hover {
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
  }
}

.back-button-disabled {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding-left: 10;
  margin: 10px 10px 0 10px;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #ffffff;
}

.home-button {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #ffffff;
  &:hover {
    box-shadow: 0 5px 7px 0 rgba(0, 0, 0, 0.15);
  }
}

.home-button-disabled {
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  width: 34px;
  height: 34px;
  border-radius: 10px;
  background-color: #ffffff;
}
