$dropdown-item-height: 48px;

.menu-container-three-dot {
  width: 100%;
  height: 100%;
  display: inline-block;
  position: relative;

  &:hover .itemContainer,
  &:focus .itemContainer {
    display: block;
    opacity: 1;
    transform: translateY(-10px);
    pointer-events: initial;
  }

  .itemContainer {
    background-color: #fff;
    z-index: 1001;
    position: absolute;
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
    width: 110px;
    top: 0;
    left: -100px;
    opacity: 0;
    transition: opacity 0.5s, transform 0.3s;
    pointer-events: none;

    .menu-item {
      cursor: pointer;
      width: inherit;
      height: $dropdown-item-height;
      padding-left: 16px;
      align-items: center;
      .menu-inner-container {
        width: inherit;
        box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
        background-color: #fff;
        margin-left: 85px;
        margin-top: 125px;
        visibility: hidden;
        opacity: 0;
        transition: all 0.5s ease;
        display: none;
        height: fit-content;
        svg {
          margin-right: 30px;
          margin-left: auto;
        }
      }

      svg {
        margin-right: 16px;
      }

      &:hover {
        background-color: rgba(216, 216, 216, 0.4);
      }

      &:hover > .menu-inner-container,
      .menu-inner-container:hover {
        visibility: visible;
        opacity: 1;
        display: block;
      }
    }
  }
}
