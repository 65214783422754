.partner-table-wrapper {
  padding: 0 84px;
  padding-top: 130px;
  overflow: scroll;
  height: 100vh; // This is needed so the hover menu doesn't get cut off
}

.partner-table {
  width: 100%;
  margin: auto;

  .partner-table-header {
    background-color: white;
    border: solid 1px #acacac;
    border-radius: 8px 8px 0 0;
    border-bottom-width: 0;
    height: 84px;
    padding: 0 20px;
    .back {
      font-size: 12px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        background-color: #e7e7e7;
      }
    }
    h1 {
      margin: 0;
      font-size: 21px;
      font-weight: normal;
      margin-left: 30px;
    }

    .button-action {
      padding: 8px 10px;
      height: 22px;
      margin-left: auto;
      cursor: pointer;

      label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        font-weight: 500;
        margin-left: 5px;
        cursor: inherit;
        margin-bottom: 0px !important;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  .empty-table {
    text-align: center;
    padding-top: 10px;
  }

  .table-header {
    display: flex;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 10px;
    margin-right: 10px;
    h2 {
      font-size: 18px;
      margin-bottom: 0;
      margin-right: 10px;
    }
  }
  table {
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 5px;
    margin-bottom: 30px;
    td {
      &:first-of-type {
        border-left: solid 1px #acacac;
      }
    }
    thead {
      background-color: #f2f2f2;
      td {
        border-top: solid 1px #acacac;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        vertical-align: middle;
        height: 40px;
        padding: 0 10px;
      }
    }
    tbody {
      td {
        color: black;
        font-size: 14px;
        background-color: white;
        border-top: solid 1px #acacac;
        border-left: solid 1px #acacac;
        padding: 5px 10px;
        vertical-align: top;
        input {
          -webkit-appearance: none;
          border: none;
        }
        &.child-container {
          padding-right: 0;
          padding-left: 58px;
          border-top: none;
        }
      }
      td:first-of-type {
        border-left-width: 2px;
      }
      tr {
        cursor: pointer;
      }
      tr.pending td {
        background-color: #f2f2f2;
        color: #555;
        font-style: italic;
      }
      tr:hover > td {
        background-color: #dddddd;
        tr:hover > td {
          background-color: #cfcfcf;
          tr:hover > td {
            background-color: #c2c2c2;
            tr:hover > td {
              background-color: #eaeaea;
            }
          }
        }
      }
      tr:hover > td:first-of-type {
        border-left-width: 2px;
        border-left-color: #333;
      }
      tr.pending:hover td {
        background-color: #e4e4e4;
      }
      tr:last-of-type {
        > td {
          border-bottom: solid 1px #acacac;
        }
      }
      tr.expanded {
        td {
          border-bottom: none;
        }
        > td {
          .chevron {
            transform: rotate(90deg);
          }
        }
      }
    }
    .chevron {
      transition: transform 0.1s;
    }
  }

  .child-container {
    background-color: white;
    background-color: #f3f3f3;
    table {
      tr {
        td {
          vertical-align: middle;
          border-right: none !important;
          &.published {
            background-color: rgb(170, 227, 170) !important;
            font-weight: bold;
          }
          &.not-published {
            background-color: rgb(242, 95, 95) !important;
            font-weight: bold;
          }
        }
      }
    }
  }
}


.copy-icon {
  font-size: 14px !important;
  margin-right: 10px;
  color: #333;
  &:hover {
    color: #888;
  }
}
.lint-correct {
  background-color: rgb(104, 231, 121) !important;
  font-weight: bold;
}
.lint-error {
  background-color: rgb(241, 48, 34) !important;
  font-weight: bold;
}
.display-linebreak {
  white-space: pre-line;
}

.table-button {
  -webkit-appearance: none;
  appearance: none;
  border: none;
  padding: 0;
  background-color: initial;
  margin: 0 5px;
  font-weight: 600;
  font-size: 14px;
  &:hover {
    text-decoration: underline;
  }
  &.backgrounded {
    background-color: white;
    border: 2px solid #777;
    border-radius: 3px;
    padding: 3px 5px;
    &:hover {
      background-color: #eee;
    }
  }
}
