@import '~bootstrap/scss/bootstrap';

body::-webkit-scrollbar {
  display: none;
}

// GUI classes
body .dg.ac {
  z-index: 1000000;
}

div:not(.show-scrollbar)::-webkit-scrollbar {
  display: none;
}

div#root {
  // height: 100vh;
  // overflow: hidden;
}

.Section__container___3YYTG {
  height: inherit;
}

input:focus {
  outline: none;
}

button:hover {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto', sans-serif;
}

.version {
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 10px;
  color: #333;
  z-index: 10000;
  font-size: 16px;
  opacity: 0;
  cursor: pointer;
  transition: opacity 0.2s;
  &:hover {
    opacity: 1;
  }
}

.h4 {
  height: initial;
}

label {
  margin-bottom: 0;
}

.map-label {
  width: 130px;
  margin-top: -10px;
  padding: 20px 0;
  margin-left: 80px;
  font-family: 'Roboto', sans-serif;
  font-weight: bold;
  font-size: 12px;
  color: black;
  -webkit-text-fill-color: black;
  text-shadow: -1px 1px 0px #fff, 1px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff,
    0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
  cursor: pointer;
  transition: opacity 0.2s, margin-left 0.2s;
  opacity: 1;
  text-align: left;

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  // &:hover,
  &.highlighted {
    text-decoration: underline;
    margin-left: 83px;
  }
  &.selected {
    color: #e72002;
    -webkit-text-fill-color: #e72002;
    text-shadow: -1px 1px 0px #fff, 1px 1px 0px #fff, 1px -1px 0px #fff, -1px -1px 0px #fff,
      0 1px 0 #fff, 1px 0 0 #fff, -1px 0 0 #fff, 0 -1px 0 #fff;
    margin-left: 83px;
  }
}

.map-icon {
  height: 35px;
  margin-top: -10px;
  padding: 5px;

  cursor: pointer;
  transition: opacity 0.2s, height 0.2s, margin-left 0.2s, margin-top 0.2s;
  opacity: 1;

  // image-rendering: optimizeSpeed; /*                     */
  // image-rendering: -moz-crisp-edges; /* Firefox             */
  // image-rendering: -o-crisp-edges; /* Opera               */
  // image-rendering: -webkit-optimize-contrast; /* Chrome (and Safari) */
  // image-rendering: optimize-contrast; /* CSS3 Proposed       */
  // -ms-interpolation-mode: nearest-neighbor; /* IE8+                */

  &.hidden {
    opacity: 0;
    pointer-events: none;
  }
  &.selected {
    height: 45px;
    margin-top: -13px;
  }
  // &:hover,
  &.highlighted {
    height: 45px;
    margin-top: -13px;
  }
  &.outlined{
    border: thin dashed #e72002;
    border-radius: 50%;
  }
  &.door-icon {
    height: 30px;
    &.selected {
      height: 45px;
      margin-top: -13px;
    }
    // &:hover,
    &.highlighted {
      height: 45px;
      margin-top: -13px;
    }
  }
  &.no-text {
    margin-left: 0 !important;
  }
}
