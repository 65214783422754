.popup-box {
  position: relative;
  bottom: 0;
  width: 100%;
  height: 72px;
  border: solid 1px #979797;
  background-color: #ffffff;
  flex-direction: row;
  align-self: flex-end;
  justify-content: space-between;
  align-items: center;
  z-index: 1001;
}

.popup-message {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #000000;
  padding-left: 10px;
}

.entityCloseButton {
  padding-left: 5px;
  button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 800;
    border: solid 1px #c6c8ca;
    background-color: #d8d8d8;
    color: #000000;
  }
  .btn:disabled {
    background-color: #d8d8d8;
    color: #6d6d6d;
    border-color: #c6c8ca;
    opacity: 1;
    cursor: default;
    &:hover {
      background-color: #d8d8d8;
      color: #6d6d6d;
      border-color: #c6c8ca;
      opacity: 1;
      cursor: default;
    }
  }
  .btn:hover {
    border: solid 1px #c6c8ca;
    background-color: #d8d8d8;
    color: #000000;
  }
  .btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    border: solid 1px #c6c8ca;
    background-color: #d8d8d8;
    color: #000000;
  }
}

.save-button-box {
  padding-left: 3px;
  margin-right: 20px;
  button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 800;
    border: solid 1px #000;
  }
  .btn:disabled {
    background-color: #d8d8d8;
    color: #6d6d6d;
    border-color: #c6c8ca;
    opacity: 1;
    cursor: default;
    &:hover {
      background-color: #d8d8d8;
      color: #6d6d6d;
      border-color: #c6c8ca;
      opacity: 1;
      cursor: default;
    }
  }
  .btn:hover {
    color: #212529;
    background-color: #ffc107;
    border-color: #000;
  }
  .btn:focus {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
    color: #212529;
    background-color: #ffc107;
    border-color: #000;
  }
}

.info {
  height: 20px;
}

.message-box {
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 10px 0px rgba(0, 0, 0, 0.16), 0 2px 6px 0px rgba(0, 0, 0, 0.26);
  padding: 20px 8px;
  font-weight: normal;
  top: -90px;
  right: 0;
  width: 210px;
  letter-spacing: 0.25px;
}

// .save-button {
//   padding: 4px 8px 4px 8px;
//   text-align: center;
//   font-family: 'Roboto', sans-serif;
//   font-size: 14px;
//   font-weight: bold;
//   font-stretch: normal;
//   font-style: normal;
//   line-height: normal;
//   letter-spacing: 2px;
//   color: #000000;

//   .disabled {
//     color: #c4c4c4;
//   }
// }
