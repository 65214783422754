.edit-mode-banner {
  width: calc(100vw - 384px);
  height: 76px;
  position: absolute;
  top: 0;
  right: 0;
  z-index: 899;
  border: solid 1px yellow;
  background-color: white;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .edit-mode-text {
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    margin-right: 15px;
  }

  .edit-banner-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    padding: 0 15px 0 15px;
    margin-right: 15px;
    border-radius: 4px;
    border: solid 1px #c6c8ca;
    background-color: #d8d8d8;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    cursor: pointer;

    &.disabled {
      color: #6d6d6d;
    }
    &.enabled {
      background: #ffc107;
      border: solid 1px #000000;
    }
  }

  .save-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 36px;
    border: solid 1px #000;
    border-radius: 4px;
    background-color: #ffc107;

    padding: 4px 12px 4px 12px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.25px;
    color: #000000;

    cursor: pointer;
    &:hover {
      box-shadow: 0 0 7px 0 rgba(0, 0, 0, 0.15);
    }
  }
}
