.map-viewer,
.label-renderer {
  width: calc(100vw - 384px);
  height: calc(100vh - 64px);
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 10;

  &.resized {
    width: 100%;
  }
}
.building-viewer {
  width: 100vw;
  height: calc(100vh - 64px);
  margin-top: 64px;
  box-sizing: border-box;
  position: relative;
  background: transparent;

  // -webkit-touch-callout: none; /* iOS Safari */
  // -webkit-user-select: none; /* Safari */
  // -khtml-user-select: none; /* Konqueror HTML */
  // -moz-user-select: none; /* Old versions of Firefox */
  // -ms-user-select: none; /* Internet Explorer/Edge */
  // user-select: none; /* Non-prefixed version, currently supported by Chrome, Opera and Firefox */

  canvas:focus {
    outline: none;
    // cursor: pointer;
  }

  .canvas {
    .container {
      margin-left: -10px !important;
      margin-top: -20px !important;
      padding-left: 0px !important;
      padding-right: 0px !important;
      margin-right: 0px !important;
      display: flex;
      width: max-content;
    }
  }

  canvas {
    width: calc(100vw - 384px);
    height: calc(100vh - 64px);
    // position: relative;
    // width: 80%;
    // height: 80%;
  }

  .floor-selector-container {
    position: fixed;
    z-index: 800;
    top: 182px;
    left: 85px;
  }

  button {
    text-transform: none;
  }
  h3 {
    font-size: 1.17rem !important;
  }
}
