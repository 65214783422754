.users-table-wrapper {
  padding: 0 84px;
  padding-top: 130px;
  overflow: scroll;
  height: 100%;
  min-width: 1500px;
}

.users-table {
  // max-width: 1250px;
  min-width: 1024px;
  width: 100%;
  margin: auto;
  // margin-top: 130px;

  .users-table-header {
    background-color: white;
    border: solid 1px #acacac;
    border-radius: 8px 8px 0 0;
    border-bottom-width: 0;
    padding: 20px 20px 20px 40px;
    .back {
      font-size: 12px;
      width: 18px;
      height: 18px;
      border-radius: 9px;
      cursor: pointer;
      margin-right: 10px;
      &:hover {
        background-color: #e7e7e7;
      }
    }
    .search-input {
      margin-bottom: 0;
      margin-top: 10px;
      width: 250px;
    }
    h1 {
      margin: 0;
      font-size: 21px;
      font-weight: normal;
      // margin-left: 30px;
    }

    .button-action {
      padding: 8px 10px;
      height: 22px;
      margin-left: auto;
      cursor: pointer;

      label {
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 1.25px;
        font-weight: 500;
        margin-left: 5px;
        cursor: inherit;
        margin-bottom: 0px !important;
      }

      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }

  table {
    width: 100%;
    min-width: 1024px;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 5px;
    margin-bottom: 30px;
    td {
      &:first-of-type {
        border-left: solid 1px #acacac;
      }
      &:last-of-type {
        border-right: solid 1px #acacac;
      }
    }
    thead {
      background-color: #f2f2f2;
      // position: sticky;
      // top: 44px;
      td {
        border-top: solid 1px #acacac;
        font-size: 14px;
        font-weight: 600;
        text-transform: uppercase;
        vertical-align: middle;
        height: 40px;
        padding: 0 10px;
      }
    }
    tbody {
      td {
        color: black;
        font-size: 14px;
        background-color: white;
        border-top: solid 1px #acacac;
        padding: 5px 10px;
        vertical-align: top;
        input {
          -webkit-appearance: none;
          border: none;
        }
        button {
          -webkit-appearance: none;
          appearance: none;
          border: none;
          padding: 0;
          background-color: initial;
          margin: 0 5px;
          font-weight: 600;
          &:hover {
            text-decoration: underline;
          }
        }
      }
      tr {
        cursor: pointer;
      }
      tr.pending td {
        background-color: #f2f2f2;
        color: #555;
        font-style: italic;
      }
      tr:hover td {
        background-color: #efefef;
      }
      tr.pending:hover td {
        background-color: #e4e4e4;
      }
      tr:last-of-type {
        td {
          border-bottom: solid 1px #acacac;
        }
        td:first-of-type {
          border-radius: 0 0 0 8px;
        }
        td:last-of-type {
          border-radius: 0 0 8px 0;
        }
      }
    }
  }
}
