#add-user {
  h1 {
    font-size: 18px;
    margin-bottom: 0;
  }
  h2 {
    font-size: 16px;
    margin-top: 0;
  }
  p {
    font-size: 12px;
  }

  .sections {
    display: flex;
    flex-direction: row;
    .section {
      flex: 1;
      &:not(:first-of-type) .selection-table {
        border-left-width: 0;
      }
    }
  }
  .selection-table {
    border: 2px solid #999;
    height: 220px;
    overflow: scroll;
    position: relative;
    margin-bottom: 20px;
    &.all-selected {
      opacity: 0.3;
      &:hover {
        opacity: 1;
      }
    }
    .selectable-element {
      font-size: 12px;
      padding: 8px 10px;
      &:nth-of-type(2n) {
        background-color: #f3f3f3;
      }
      &.selected {
        background-color: #acd1af;
      }
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }
    &.role {
      height: initial;
      .selectable-element {
        h3 {
          font-size: 14px;
          margin-bottom: 0;
          margin-top: 0;
        }
      }
    }
    .selection-table-loader {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  h2 {
    font-size: 14px;
  }
}
// .add-user {
//   .error-container {
//     background-color: #fff;
//     border: solid 1px #acacac;
//     border-radius: 4px;
//     max-width: 1250px;
//     min-width: 1024px;
//     width: 100%;
//     margin: auto;
//     margin-bottom: 16px;
//     padding: 16px;
//     display: flex;
//     flex-direction: column;
//     justify-content: center;
//     align-items: center;

//     svg {
//       width: 40px;
//       height: 40px;
//       color: #d93732;
//     }
//     h1 {
//       font-size: 21px;
//     }
//     div {
//       font-size: 16px;
//       a {
//         color: #0075fe;
//         text-decoration: underline;
//       }
//     }
//   }

//   .selection-table {
//     border: 3px solid gray;
//   }
//   .users-table-wrapper {
//     padding: 0 44px;
//     margin-top: 130px;
//   }

//   tbody tr {
//     height: 100px;
//   }

//   .users-table table tbody tr:hover td {
//     background-color: #fff;
//   }
// }
