.topnav-tab {
  margin: 0px 25px;
  padding: 20px 0px;
  font-size: 14px;
  letter-spacing: 0.25px;
  cursor: pointer;
  &.selected {
    border-bottom: 4px solid white;
  }
  &.b-nav-link {
    color: #fff;
  }
}

.tabs:hover {
  .topnav-tab:not(:hover) .tab-label {
    opacity: 0.7;
  }
}
