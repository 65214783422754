.controls-box {
  position: absolute;
  left: 410px;
  bottom: 20px;
  z-index: 1001;
  &:hover .info-box {
    opacity: 1;
    transform: translate(0, 0);
  }

  .button {
    padding: 2px 6px 2px 6px;
    border-radius: 4px;
    border: solid 2px #979797;
    font-family: LucidaGrande;
    background-color: #f9f9f9;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #000;
  }

  .close-button {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    z-index: 1001;
  }

  .close-button-icon {
    cursor: pointer;
  }

  .controls {
    color: #555;
    background-color: #ddd;
    border: 1px solid #555;
    border-radius: 4px;
    font-size: 11px;
    font-weight: bold;
    text-transform: uppercase;
    padding: 3px 6px;
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }

  .info-box {
    pointer-events: none;
    border-radius: 4px;
    border: solid 1px #979797;
    background-color: #ffffff;
    position: absolute;
    display: flex;
    flex-direction: row;
    opacity: 0;
    bottom: 30px;
    left: 0;
    transform: translate(0, 10px);
    transition: transform 0.2s, opacity 0.2s;
    margin-top: -40px;
    font-family: Roboto;
    font-size: 14px;
  }

  .info-box > div:not(:last-child) {
    border-right: solid 1px #979797;
  }

  .action-box {
    display: flex;
    flex-direction: column;
  }

  .shortcut-box {
    display: flex;
    flex-direction: column;
    padding: 12px 0 12px 20px;
    > .label-box {
      margin-bottom: 10px;
    }
  }

  .hotkey-box {
    display: flex;
    flex-direction: row;
    .label {
      margin-right: 7px;
    }
    .button {
      width: auto;
      .label {
        margin-right: 0;
      }
    }
  }

  .button-box {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
  }

  .hotkey-button-box {
    display: flex;
    flex-direction: column;
  }

  // .label-box {
  //   margin-left: 16px;
  // }

  .label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.43;
    letter-spacing: 0.25px;
    color: #000000;
    &.plus {
      margin: 0 7px;
    }
  }
}
