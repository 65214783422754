.client-user-card-container {
  margin: auto;
  margin-top: 162px;
  background-color: #ffffff;
  max-width: 1250px;
  padding: 0 50px;
  padding-top: 101px;
  padding-bottom: 100px;

  .client-admin-card {
    width: 459px;
    height: 290px;
    padding: 40px 50px;
    border-radius: 4px;
    border: solid 1px #979797;
    margin: auto;
  }

  .studio-card-header {
    color: #d28207;
    justify-content: center;
    align-items: center;
  }

  .formatting-tip {
    flex: 1;
    color: #5d5d5d;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 438px;
    margin: 14px auto auto auto;
  }

  .formatting-tip-img {
    margin-bottom: 30px;
    margin-right: 6px;
  }

  .space-fill {
    flex: 1;
    padding: 54px 68px 38px 75px;
    margin-right: 117px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    margin-top: 48px;
  }

  .email-support-button {
    background-color: #ffc107;
    color: #000000;
    border-color: #000000;
    font-weight: bold;
  }
  .email-support-button:hover {
    background-color: #ffc107;
    color: #000000;
    border-color: #000000;
  }
  .landing-page-background {
    background-image: url('../../assets/landing-bg.png');
    background-size: cover;
    background-position: center;
  }
}

.explore-card-header {
  color: #349ccd;
  justify-content: center;
  align-items: center;
}

// lg breakpoint for bootstrap
@media (max-width: 992px) {
  .client-admin-card {
    width: 100%;
    height: fit-content;
  }
  .client-admin-studio-card {
    margin-top: 50px;
  }

  .formatting-tip {
    width: 100%;
    margin: auto;
    margin-top: 30px;
  }
}
