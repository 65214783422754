.mobile-container {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 100vh;
  font-family: Roboto;
  font-size: 20px;
  background: linear-gradient(to bottom, #f6c335, #f9d266 50%, #ffde83 75%);

  .mobile-heading-container {
    margin-top: 60px;
    margin-bottom: 55px;
    .mobile-heading {
      font-size: 36px;
      @media screen and (min-width: 601px) {
        font-size: 72px;
      }

      color: white;
      font-weight: 300;
      span {
        font-weight: 900;
      }
    }
  }

  .mobile-inner-container {
    max-width: 285px;
    max-height: 170px;

    @media screen and (min-width: 601px) {
      max-width: 498px;
      max-height: 163px;
    }

    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 10px;
    border-radius: 15px;
    background-color: #ffffff;

    .inner-heading {
      font-size: 20px;

      @media screen and (min-width: 601px) {
        font-size: 25px;
      }
    }

    .inner-content {
      font-size: 12px;

      @media screen and (min-width: 601px) {
        font-size: 17px;
      }
      color: #0e0e0e;
    }
  }
}
