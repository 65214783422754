.navbar {
  padding: 0px 0px;
  .top-nav {
    background: #2e2e2e;
    z-index: 900;
    height: 64px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
    .separator {
      margin: 0 12px;
      border-right: 1px solid #e7e7e7;
      height: 12px;
      margin-bottom: -3px;
    }
    .studio-logo {
      color: #fff;
      margin: 10px -15px;
    }
  }
}
