.editor-root {
  width: 384px;
  overflow-y: hidden;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 899;
  border-right: solid 1px #979797;

  & > div:first-child {
    position: relative;
    height: 100%;
  }

  p.cancel {
    font-size: 18px;
    text-decoration: underline;
    color: #d93732;
    cursor: pointer;
  }
  h3 {
    font-size: 1.05rem !important;
  }
}
