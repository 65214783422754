.header-box {
  width: 384px;
  padding-top: 84px;
  padding-bottom: 20px;
  border-bottom: solid 1px #979797;
  background-color: #f0efef;
}

.header {
  display: flex;
  flex-direction: row;
  margin-left: 20px;
  margin-right: 10px;
  .title {
    width: 320px;
    font-family: 'Roboto', sans-serif;
    font-size: 31px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 0.55px;
    color: #000000;
  }
  .icon {
    width: 34px;
    height: 34px;
    margin-right: 10px;
  }
}
