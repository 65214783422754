.zoom-control {
  margin-top: 29px;
  display: flex;
  flex-direction: column;
  button {
    border: 1px solid #d0d3d5;
    width: 30px;
    height: 30px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 30px;
    margin-top: 1px;
    padding-top: 2px;
    background: white;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #091262;
    display: flex;
    align-items: center;
    justify-content: center;
    &:hover:not(.selected) {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }
  }
}
