.floor-selector {
  button {
    border: 1px solid #d0d3d5;
    border-bottom: none;
    border-top: 1px solid #d0d3d5;
    min-width: 30px;
    max-width: 180px;
    width: 100%;
    text-align: center;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    height: 30px;
    margin-top: 1px;
    background: white;
    color: #091262;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    padding: 0 10px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: left;
    color: #091262;
    &:hover:not(.selected) {
      opacity: 0.8;
    }
    &:focus {
      outline: none;
    }

    &.selected {
      background: #414143;
      color: white;
    }
  }
  a {
    text-align: center;
  }
  a:first-of-type button {
    border-radius: 3px 3px 0 0;
  }
  a:last-of-type button {
    border-radius: 0 0 3px 3px;
  }
}
