.row-checkbox-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
  margin-bottom: 20px;
  padding: 8px 12px;
  cursor: pointer;

  .label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
  }
  .checkbox-box {
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
}
