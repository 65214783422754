.link {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  min-height: 40px;
  border-radius: 4px;
  border: solid 1px #979797;
  background-color: #ffffff;
  margin-bottom: 18px;
  padding: 8px 12px;
  cursor: pointer;

  .link-icon {
    margin-right: 5px;
    width: 23px;
    height: 23px;
  }

  .label {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #000000;
  }

  .sub-label {
    color: #979797;
    font-size: 14px;
  }

  .count-box {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    flex-grow: 1;

    .count-container {
      display: flex;
      align-items: center;
      font-family: 'Roboto', sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #7d7d7d;
    }
  }
}
