.user-dropdown .dropdown-style {
  cursor: pointer;
  .inner {
    width: 162px;
    min-height: 48px;
  }
}

.user-dropdown {
  .user-b-dropdown {
    .btn {
      font-size: 14px;
      border-color: transparent;
      border-radius: 9999px;
      height: 32px;
      text-align: center;
      align-items: center;
      background-color: transparent;
      line-height: 1;
      &:hover {
        box-shadow: 0 0 0 1px #fff;
        background: hsla(0, 0%, 100%, 0.12);
      }
      &:focus {
        border-color: transparent;
        box-shadow: 0 0 0 1px #fff !important;
        background: hsla(0, 0%, 100%, 0.12);
      }
    }
    .dropdown-toggle::after {
      display: none;
    }
    .dropdown-menu {
      position: absolute;
      background-color: #fff;
      z-index: 1000;
      top: 36px;
      box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 6px 0 rgba(0, 0, 0, 0.26);
      font-family: Helvetica;
      font-size: 15px;
      color: #4d4d4e;
      border-radius: 0px;
      border: none;
      padding: 8px 0 4px;
    }
    .dropdown-item {
      padding: 0px 0px;
      &:focus {
        background-color: lightgray;
      }
    }
  }
}
