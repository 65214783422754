.cancel-button {
  button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 800;
    border: solid 1px #c6c8ca;
    background-color: #d8d8d8;
    color: #000000;
  }
  .btn:hover {
    background-color: #d8d8d8;
    color: #000000;
  }
}

.delete-button {
  button {
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    font-weight: 800;
    background-color: #ff0000;
    color: #000000;
  }
  .btn:hover {
    background-color: #ff0000;
    color: #000000;
  }
}

.dialogue-background {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  display: flex;

  .dialogue-container {
    position: relative;
    margin: auto;
    background-color: #fff;
    width: 360px;
    padding: 20px 12px 12px 20px;
    min-height: 212px;
    border-radius: 10px;
  }
}

.dialogue-header {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  letter-spacing: 0.29px;
}

.dialogue-body {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
}

.dialogue-buttons {
  display: flex;
  flex-direction: row-reverse;
  margin-top: 60px;

  .dialogue-button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 42px;
    padding: 0 15px 0 15px;
    border-radius: 4px;
    border: solid 1px #c6c8ca;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.13;
    letter-spacing: normal;
    text-align: center;
    color: #000000;
    cursor: pointer;
    &.delete {
      background-color: #ff0000;
      margin-left: 16px;
    }

    &.cancel {
      background-color: #d8d8d8;
    }
  }
}
