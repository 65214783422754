.building-viewer-controls {
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: 200px;
  min-width: 150px;
  top: 100px;
  right: 40px;
  z-index: 800;
  bottom: 0;
  overflow: auto;
}

.map-controls {
  z-index: 800;
  margin-top: 27px;
}
