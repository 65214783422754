.context-menu-container {
  position: absolute;
  width: 148px;
  border-radius: 4px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  background-color: #ffffff;
  z-index: 100;
  overflow: hidden;
}

.context-menu-option {
  display: flex;
  height: 26px;
  &:hover {
    background-color: #eeeeee;
  }
}

.menu-option-label {
  font-size: 14px;
  line-height: 24px;
  margin-left: 24px;
}
